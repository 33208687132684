<div class="info-container">
  <mat-grid-list class="grid-container" [cols]="(isMobile$ | async) ? 1 : 3" rowHeight="300px">
    <mat-grid-tile class="image-info-tile" *ngFor="let imageInfo of imageInfos" [colspan]="1" [rowspan]="1">
      <div class="image-info-container" routerLink="{{imageInfo.urlRedirect}}">
        <ngx-picture class="image-info-image"
          alt="{{imageInfo.title}}" 
          [src]="imageInfo.imageUrl ? imageInfo.imageUrl : imageNotFound" 
          [lazyLoad]="true">
        </ngx-picture>
        <span class="image-info-title">
          {{imageInfo.title}}
        </span>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</div>