import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ProjectDataResponse } from 'src/app/models/response.model';
import { ImageSQL, ProjectSQL } from 'src/app/models/sqlStruct.model';
import { Tile } from 'src/app/models/tileGrid.model';
import { ProjectsService } from 'src/app/services/projects.service';

@Component({
  selector: 'app-projectdetails',
  templateUrl: './projectdetails.component.html',
  styleUrls: ['./projectdetails.component.scss']
})
export class ProjectdetailsComponent implements OnInit {

  navigationSubscription: any;
  images: Tile[];
  projectDetails: ProjectSQL;

  constructor(public route: ActivatedRoute, private router: Router, private projectsService: ProjectsService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initiliseImages();
      }
    });
   }

  ngOnInit(): void {
  }

  initiliseImages(){
    var projectName = (<BehaviorSubject<object>>this.route.params).value["name"];

    this.projectsService.GetProjectData(projectName).subscribe (data => {
      if (data)
      {
        this.projectDetails = (<ProjectDataResponse>data).projectInfo;

        this.images = (<ProjectDataResponse>data).projectImages.map(u => 
          Object.assign (new Tile,
            {
              title: u.Name,
              imageUrl: u.Url,
              cols: 1,
              rows: 1
            })
        );
      }
    });
  }
}
