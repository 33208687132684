<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false ? false : false">
    <mat-nav-list (click)="drawer.toggle()">
      <a mat-list-item href="#" routerLink="/">Inicio</a>
      <a mat-list-item href="#" routerLink="/proyectos">Proyectos</a>
      <!-- <a mat-list-item href="#" routerLink="/products">Productos</a> -->
      <a mat-list-item href="#" routerLink="/servicios">Servicios</a>
      <a mat-list-item href="#" routerLink="/sobrenosotros">Sobre Nosotros</a>
      <!-- <a mat-list-item href="#" routerLink="/rents">Alquiler</a> -->
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="main-toolbar" color="primary" *ngIf="(isHandset$ | async) === false">
        <mat-toolbar-row class="first-row-toolbar">
          <span>
              Pida presupuesto {{personalInfo.phoneNumber}} / {{personalInfo.emailAddress}}
          </span>
          <div class="spacer"></div>
          <div class="socialMedia">
            <span>Síganos en </span>
            <a href="{{personalInfo.facebookUrl}}" target="_blank"><img class="facebookLogo" src="../../../../assets/images/logo/facebook.png"></a>
            <a href="{{personalInfo.instagramUrl}}" target="_blank"><img class="instagramLogo" src="../../../../assets/images/logo/instagram.png"></a>
          </div>
        </mat-toolbar-row>
          <mat-toolbar-row class="second-row-toolbar">
          <img class="logo" src="../../../../assets/images/logo/360DECO_Logotipo_white.png" routerLink="/">
            <div class="spacer"></div>
            <div class="sectionsContainer">
                <span routerLink="/" [className]="selectedSection('home') ? 'selected' : ''">Inicio</span>
                <span routerLink="/proyectos" [className]="selectedSection('proyectos') ? 'selected' : ''">Proyectos</span>
                <!-- <span routerLink="/products" [className]="selectedSection('products') ? 'selected' : ''">Productos</span> -->
                <span routerLink="/servicios" [className]="selectedSection('servicios') ? 'selected' : ''">Servicios</span>
                <span routerLink="/sobrenosotros" [className]="selectedSection('sobrenosotros') ? 'selected' : ''">Sobre Nosotros</span>
                <!-- <span routerLink="/rents" [className]="selectedSection('rents') ? 'selected' : ''">Alquiler</span> -->
            </div>
          </mat-toolbar-row>
    </mat-toolbar>
    <mat-toolbar class="toolbar_responsive" *ngIf="(isHandset$ | async) === true" color="accent">
        <button
          type="button"
          aria-label="Toggle sidenav"
          mat-icon-button
          (click)="drawer.toggle()"
          *ngIf="isHandset$ | async">
          <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
        </button>
        <img class="logo_responsive" src="../../../../assets/images/logo/360DECO_Logotipo_white_cropped.png" routerLink="/">
        <div class="spacer"></div>
        <div class="socialMedia">
          <a href="https://www.facebook.com/360Deco-780432242322239" target="_blank"><img class="facebookLogo" src="../../../../assets/images/logo/facebook.png"></a>
          <a href="https://www.instagram.com/360.deco/" target="_blank"><img class="instagramLogo" src="../../../../assets/images/logo/instagram.png"></a>
        </div>
    </mat-toolbar>
    <div class="router-container">
      <router-outlet class="router-container"></router-outlet>
    </div>
    <app-footage></app-footage>
  </mat-sidenav-content>
</mat-sidenav-container>