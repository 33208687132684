import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLs } from '../models/urlHelper.model';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {

  constructor(private http: HttpClient) { }

  GetProjects()
  {
    return this.http.get(URLs.urlGetProjects);
  }

  GetProjectData(projectName:string)
  {
    return this.http.get(URLs.urlGetProjectData(projectName));
  }
}
