<mat-card *ngIf="principalImage" class="principal-image">
        <img mat-card-image src="{{principalImage.url}}">
        <mat-divider [vertical]="true"></mat-divider>
        <mat-card-content class="principal-image-content">
            <a>{{principalImage.description}}</a>
        </mat-card-content>
    </mat-card>
<div class="image-carrusel" *ngIf="(isHandset$ | async) === false && imagesShowed && imagesShowed.length > 1" [style.background-color]="'primary'">
    <div class="images-container">
        <i [className]="numberIterationImage == 0 ? 'material-icons rounded md50 disabledArrow' : 'material-icons rounded md50'" 
            *ngIf="images && images.length > maxCountImage" (click)="moveCarruselImage('-')" [attr.disabled]="numberIterationImage == 0">
            arrow_back_ios
        </i>
        <div class="middle">
            <img *ngFor="let image of imagesShowed" (click)="changePrincipalImage(image)" mat-card-image src="{{image.url}}">
        </div>
                <i [className]="numberIterationImage > (images.length/maxCountImage - 1) ? 'material-icons rounded md50 disabledArrow' : 'material-icons rounded md50'" 
                *ngIf="images && images.length > maxCountImage" (click)="moveCarruselImage('+')" [attr.disabled]="numberIterationImage < (images.length/maxCountImage - 1)">
                    arrow_forward_ios
                </i>
    </div>
</div>

<div class="image-carrusel-responsive" *ngIf="(isHandset$ | async) === true">
    <div class="images-container-responsive">
        <img *ngFor="let image of images" (click)="changePrincipalImage(image)" mat-card-image src="{{image.url}}">
    </div>
</div>
