import { Component, Input, OnInit } from '@angular/core';
import { map, shareReplay } from 'rxjs/operators';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ImagedialogComponent } from '../imagedialog/imagedialog.component';
import { MatPaginatorIntl, PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-imagecollage',
  templateUrl: './imagecollage.component.html',
  styleUrls: ['./imagecollage.component.scss']
})
export class ImagecollageComponent implements OnInit {
  itemsPerPage = 12;
  _projectImages = [];
  pagedImages = [];
  projectImagesLength: number = 0;

  @Input() set projectImages(value: [])
  {
    if (value)
    {
      this._projectImages = value;
      this.pagedImages = this._projectImages.slice(0, this.itemsPerPage);
      this.projectImagesLength = this._projectImages.length;
    }
  }

  isMobile$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  isTablet$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Tablet, Breakpoints.Medium])
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(private breakpointObserver: BreakpointObserver, private dialog: MatDialog, private paginator: MatPaginatorIntl) {
    this.paginator.itemsPerPageLabel = "Imágenes por página: ";
    this.paginator.previousPageLabel = "Página anterior";
    this.paginator.nextPageLabel = "Página siguiente";
    this.paginator.lastPageLabel = "Última página";
    this.paginator.getRangeLabel = this.spanishGetRangeLabel.bind(this);
   }

  ngOnInit(): void {
  }

  openImageDialog(url: string) {
    this.dialog.open(ImagedialogComponent, {
      data: {
        imageUrl: url,
        images: this._projectImages
      }
    });
  }

  onPageChange(event: PageEvent){
    let startIndex = event.pageIndex * this.itemsPerPage;
    let endIndex = startIndex + this.itemsPerPage;
    if(endIndex > this.projectImagesLength){
      endIndex = this.projectImagesLength;
    }
    this.pagedImages = this._projectImages.slice(startIndex, endIndex);
  }

  private spanishGetRangeLabel(page: number, pageSize: number, length: number): string {
    if (length == 0 || pageSize == 0) {
      return `0 de ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    
    const endIndex = startIndex < length ?
        Math.min(startIndex + pageSize, length) :
        startIndex + pageSize;
    return `${startIndex + 1} – ${endIndex} de ${length}`;
  }
}
