import { Component, OnInit } from '@angular/core';
import { ListElement } from 'src/app/models/modelsHelper.model';
import { ProjectSQL } from 'src/app/models/sqlStruct.model';
import { Tile } from 'src/app/models/tileGrid.model';
import { ProjectsService } from 'src/app/services/projects.service';
import { ScrollerService } from 'src/app/services/scroller.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit {

  tiles: Tile[];
  projects: ProjectSQL[];

  years:ListElement[];

  categories:ListElement[];

  constructor(private projectsService: ProjectsService, private scroller: ScrollerService) { }

  ngOnInit() {
    this.projectsService.GetProjects().subscribe (data => {
      if (data)
      {
        this.projects = (<Array<ProjectSQL>>data);

        var distinctCategories = [...new Set ((<Array<ProjectSQL>>data).map( p => p.Category ))];

        this.categories = distinctCategories.map(c => 
          Object.assign(new ListElement,
            {
              name: c,
              selected: true
            })
          );

        var distinctYears = [...new Set ((<Array<ProjectSQL>>data).map( p => (new Date (p.Date)).getFullYear() ))];
        this.years = distinctYears.map (y =>
          Object.assign(new ListElement,
            {
              name: y.toString(),
              selected: true
            }
          )
        );

        this.updateGridContent();
      }
    });
  }

  ngAfterViewInit() {
    this.scroller.scrollToTop();
  }

  onClickList(elementClicked: ListElement, listclicked: string)
  {
    if (elementClicked == null)
    {
      if (listclicked == "years")
      {
        this.years.forEach(year => year.selected = true);
      }
      else if (listclicked == "categories")
      {
        this.categories.forEach(category => category.selected = true);
      }
    }
    else
    {
      elementClicked.selected = !elementClicked.selected;
    }

    this.updateGridContent();
  }

  checkEveryElementSelected (list: ListElement[])
  {
    if (list)
    {
      return list.findIndex(element => !element.selected) == -1 ? true : false;
    }
    else
    {
      return false;
    }
  }

  updateGridContent()
  {
    var selectedYears = this.years.filter(year => year.selected).map(year => year.name);
    var selectedCategories = this.categories.filter(category => category.selected).map(category => category.name);

    var updatedProjects = this.projects.filter(project => 
        selectedYears.indexOf((new Date (project.Date)).getFullYear().toString()) != -1 && 
        selectedCategories.indexOf(project.Category) != -1
    );

    this.tiles = updatedProjects.map(project => 
      Object.assign (new Tile,
        {
          title: project.Name,
          cols: 1,
          rows: 1,
          imageUrl: project.ImageUrl
        })
    )
  }
}
