<div class="container">
    <mat-grid-list class="grid-container" [cols]="(isMobile$ | async) ? 1 : 3" rowHeight="200px">
        <mat-grid-tile class="logo-container" [colspan]="1" [rowspan]="1">
            <img class="logo" src="../../../../assets/images/logo/360DECO_Logotipo_white.png" routerLink="/">
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <div class="contact info-container">
                <div class="header">
                    <h2 mat-h2 class="title">Contacto</h2>
                    <mat-divider class="footage-divider"></mat-divider>
                </div>
                <div class="contact-info">
                    <mat-icon class="contact-icon">location_on</mat-icon>
                    <a class="contact-text">{{personalInfo.officeAddress}}</a>
                </div>
                <div class="contact-info">
                    <mat-icon class="contact-icon">smartphone</mat-icon>
                    <a class="contact-text">{{personalInfo.phoneNumber}}</a>
                </div>
                <div class="contact-info">
                    <mat-icon class="contact-icon">email</mat-icon>
                    <a class="contact-text link" href="mailto:{{personalInfo.emailAddress}}">{{personalInfo.emailAddress}}</a>
                </div>
            </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <div class="social-media info-container">
                <div class="header">
                    <h2 mat-h2 class="title">Redes Sociales</h2>
                    <mat-divider class="footage-divider"></mat-divider>
                </div>
                <div class="social-media-info">
                    <img class="social-media-icon" src="../../../../assets/images/common/facebookwhiteicon.png">
                    <a href="{{personalInfo.facebookUrl}}" target="_blank" class="social-media-text link">
                        {{personalInfo.facebookName}}
                    </a>
                </div>
                <div class="social-media-info">
                    <img class="social-media-icon" src="../../../../assets/images/common/instagramwhiteicon.png">
                    <a href="{{personalInfo.instagramUrl}}" target="_blank" class="social-media-text link">
                        {{personalInfo.instagramName}}
                    </a>
                </div>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</div>