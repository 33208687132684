const urlServer = "https://360deco.es/api/";

export const URLs = {

    urlGetCoveragePageImages: urlServer + "getCoveragePageImages.php",
    urlGetProducts: urlServer + "getProducts.php",
    urlGetProjects: urlServer + "getProjects.php",
    urlGetImages: urlServer + "getImages.php",
    urlGetImagesBySectionAndElement (section: string, element: string) : string {
        return urlServer + "getImages.php?section=" + section + "&element=" + element; 
    },
    urlGetProjectData (projectName:string)
    {
        return urlServer + "getProjectData.php?projectName=" + projectName;
    }
}