<!-- <div class="header-container">
    <h1 mat-h1 class="title-text">Servicios</h1>
    <mat-divider class="divider"></mat-divider>
</div> -->
<div style="height: 20px;"></div>
<div class="services-container">
    <mat-grid-list [cols]="(isMobile$ | async) ? 1 : 2" [rowHeight]="(isMobile$ | async) ? '270px' : '300px'" gutterSize="0">
        <!-- Reforma Integral -->
        <mat-grid-tile id="reformaintegral" class="coloured" [colspan]="1" [rowspan]="1">
            <div class="image-container container-left">
                <ngx-picture class="image"
                alt="Reforma" 
                src="../../../../assets/images/home/ReformaIntegral.jpg" 
                [lazyLoad]="true">
            </ngx-picture>
            </div>
        </mat-grid-tile>
        <mat-grid-tile class="coloured" [colspan]="1" [rowspan]="1">
            <div class="text-container container-right">
                <h2 mat-h2 class="text-title">Reforma Integral</h2>
                <span class="text">En 360deco nos importa tu reforma, por eso nos encargaremos de todo lo necesario para llevarla acabo. <br><br>
                    Coméntenos lo que necesita y le presentaremos un presupuesto adecuado a sus necesidades.
                </span>
            </div>
        </mat-grid-tile>
        <!-- Decoracion -->
        <mat-grid-tile id="decoracion" [colspan]="1" [rowspan]="1">
            <div class="text-container container-left" *ngIf="!(isMobile$ | async)">
                <h2 mat-h2 class="text-title">Decoración</h2>
                <span class="text">Si tiene un local o una vivienda, pero necesita decorarla a su gusto. <br><br>
                    Coméntenos su idea y la llevaremos a cabo, somos especialistas en decoración con cemento y madera.
                </span>
            </div>
            <div class="image-container container-right" *ngIf="(isMobile$ | async)">
                <ngx-picture class="image"
                    alt="Decoracion" 
                    src="../../../../assets/images/home/Decoracion.jpg" 
                    [lazyLoad]="true">
                </ngx-picture>
            </div>
        </mat-grid-tile>
        <mat-grid-tile id="decoracion" [colspan]="1" [rowspan]="1">
            <div class="image-container container-right" *ngIf="!(isMobile$ | async)">
                <ngx-picture class="image"
                    alt="Decoracion" 
                    src="../../../../assets/images/home/Decoracion.jpg" 
                    [lazyLoad]="true">
                </ngx-picture>
            </div>
            <div class="text-container container-left" *ngIf="(isMobile$ | async)">
                <h2 mat-h2 class="text-title">Decoración</h2>
                <span class="text">Si tiene un local o una vivienda, pero necesita decorarla a su gusto. <br><br>
                    Coméntenos su idea y la llevaremos a cabo, somos especialistas en decoración con cemento y madera.
                </span>
            </div>
        </mat-grid-tile>
        <!-- Pintura -->
        <mat-grid-tile id="pintura" class="coloured" [colspan]="1" [rowspan]="1">
            <div class="image-container container-left">
                <ngx-picture class="image"
                alt="Pintura" 
                src="../../../../assets/images/home/Pintura.jpg" 
                [lazyLoad]="true">
            </ngx-picture>
            </div>
        </mat-grid-tile>
        <mat-grid-tile class="coloured image-container" [colspan]="1" [rowspan]="1">
            <div class="text-container container-right">
                <h2 mat-h2 class="text-title">Pintura</h2>
                <span class="text">Somos especialistas en microcemento y en técnicas similares a este. <br><br>
                    Síganos en nuestras redes sociales para estar al día de estas.
                </span>
            </div>
        </mat-grid-tile>
    </mat-grid-list>
</div>
<div style="height: 20px;"></div>