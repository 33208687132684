import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URLs } from '../models/urlHelper.model';

@Injectable({
  providedIn: 'root'
})
export class DetailsService {
  constructor(private http: HttpClient) { }

  GetImagesBySectionAndElement(section: string, element: string) 
  {
    return this.http.get(URLs.urlGetImagesBySectionAndElement(section, element));
  }
}
