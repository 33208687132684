import { Component, OnInit  } from '@angular/core';
import { ImageSQL } from 'src/app/models/sqlStruct.model';
import { HomeService } from 'src/app/services/home.service';
import { ImageInfo } from 'src/app/models/imageInfo.model';
import { ScrollerService } from 'src/app/services/scroller.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  imagesObject: ImageSQL[];
  
  projects: ImageInfo[] = [
    {title: 'Reforma Integral', imageUrl: '../../../../assets/images/home/ReformaIntegral.jpg', urlRedirect: '/servicios/reformaintegral'},
    {title: 'Decoración', imageUrl: '../../../../assets/images/home/Decoracion.jpg', urlRedirect: '/servicios/decoracion'},
    {title: 'Pintura', imageUrl: '../../../../assets/images/home/Pintura.jpg', urlRedirect: '/servicios/pintura'}
  ];

  constructor(private homeService: HomeService, private scroller: ScrollerService) { }

  ngOnInit() {
    this.homeService.GetCoveragePageImages().subscribe (data => {
      if (data)
      {
        this.imagesObject = (<Array<ImageSQL>>data);
      }
      });
  }

  ngAfterViewInit() {
    this.scroller.scrollToTop();
  }
}
