<div class="grid-container">
    <mat-paginator [length]="projectImagesLength"
              [pageSize]="itemsPerPage"
              aria-label="Select page"
              hidePageSize="true"
              (page)="onPageChange($event)">
    </mat-paginator>
    <mat-grid-list [cols]="(isMobile$ | async) ? 1 : (isTablet$ | async) ? 2 : 4" rowHeight="250px" gutterSize="0">
        <mat-grid-tile *ngFor="let image of pagedImages" [colspan]="image.cols" [rowspan]="image.rows">
            <ngx-picture (click)='openImageDialog(image.imageUrl)' mat-card-image src="{{image.imageUrl}}" [lazyLoad]="true"></ngx-picture>
        </mat-grid-tile>
    </mat-grid-list>
</div>