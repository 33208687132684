import { Component, Input, OnInit } from '@angular/core';
import { ImageSQL } from 'src/app/models/sqlStruct.model';

@Component({
  selector: 'app-imagecarrusel',
  templateUrl: './imagecarrusel.component.html',
  styleUrls: ['./imagecarrusel.component.scss']
})
export class ImagecarruselComponent implements OnInit {
  autoTransitionTime: number = 5000;
  showedImage: ImageSQL;
  index: number = 0;
  private _imagesObject: ImageSQL[]

  @Input() set imagesObject(value: ImageSQL[])
  {
    this._imagesObject = value;
    this.changeImage(this._imagesObject[this.index], new Event("changed")); 
  }

  get imagesObject(): ImageSQL[] 
  {
    return this._imagesObject;
  }

  timer: any;

  constructor() { }

  ngOnInit() {
    this.changeImage(this._imagesObject[this.index], new Event("started"));
  }

  autoChangeImage()
  {
    if (this.index + 1 < this.imagesObject.length)
    {
      ++this.index; 
    }
    else{
      this.index = 0; 
    }

    this.changeImage(this._imagesObject[this.index], null);
  }

  changeImage(imageUrl: ImageSQL, $event:Event){
    this.index = this._imagesObject.indexOf(imageUrl);
    this.showedImage = imageUrl;
    this.showedImage.Url = this.showedImage.Url.replace(/ /g, '%20');

    if ($event != null)
    {
      this.intializeTimeInterval();
    }
  }

  intializeTimeInterval ()
  {
    if (this.timer)
    {
      clearInterval(this.timer);
    }

    this.timer = setInterval(() => {
      this.autoChangeImage();
    }, this.autoTransitionTime);
  }
}
