<!-- <div style="background-color: white;">
    <ul *ngIf="years && years.length > 1" class="item-lists">
        <li (click)="onClickList(null, 'years')" [className]="checkEveryElementSelected(years) ? 'selected' : ''">Todos </li>
        <li *ngFor="let year of years" (click)="onClickList(year, null)" [className]="year.selected ? 'selected' : ''">{{year.name}}</li>
    </ul>
    <ul *ngIf="categories && categories.length > 1" class="item-lists">
        <li (click)="onClickList(null, 'categories')" [className]="checkEveryElementSelected(categories) ? 'selected' : ''">Todos </li>
        <li *ngFor="let category of categories" (click)="onClickList(category, null)" [className]="category.selected ? 'selected' : ''">{{category.name}}</li>
    </ul> 
</div> -->

<app-imagegrid [cards]="tiles" [url]="'/proyectos/detalles/'" [buttonText]="'Más información'"></app-imagegrid>