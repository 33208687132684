import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, UrlSegment } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { ImageInformation } from 'src/app/models/imageInformation.model';
import { ImageSQL } from 'src/app/models/sqlStruct.model';
import { DetailsService } from 'src/app/services/details.service';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit, OnDestroy {

  images: ImageInformation[];
  navigationSubscription: any;

  constructor(public route: ActivatedRoute, private router: Router, private detailsService: DetailsService) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.initialiseImages();
      }
    });
   }

   initialiseImages(){
    var section = "";
    var element = "";

    if (this.route && (<Array<UrlSegment>>(<BehaviorSubject<object>>this.route.url).value).length > 0)
    {
       section = (<UrlSegment>(<BehaviorSubject<object>>this.route.url).value[0]) ? (<UrlSegment>(<BehaviorSubject<object>>this.route.url).value[0]).path : "";
       element = (<UrlSegment>(<BehaviorSubject<object>>this.route.url).value[2]) ? (<UrlSegment>(<BehaviorSubject<object>>this.route.url).value[2]).path : "";
    }

    this.detailsService.GetImagesBySectionAndElement(section, element).subscribe (data => {
      if (data)
      {
        this.images = (<Array<ImageSQL>>data).map(u => 
          Object.assign (new ImageInformation,
            {
              url: u.Url,
              description: u.Description
            })
        );
      }
    });
   }

  ngOnInit() {
  }

  ngOnDestroy() {
    // avoid memory leaks here by cleaning up after ourselves. If we  
    // don't then we will continue to run our initialiseInvites()   
    // method on every navigationEnd event.
    if (this.navigationSubscription) {  
       this.navigationSubscription.unsubscribe();
    }
  }
}
