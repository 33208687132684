import { Component, Input, OnInit } from '@angular/core';
import { ImageInformation } from 'src/app/models/imageInformation.model';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

@Component({
  selector: 'app-imagegallery',
  templateUrl: './imagegallery.component.html',
  styleUrls: ['./imagegallery.component.scss']
})
export class ImagegalleryComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet, Breakpoints.Medium])
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  private _images: ImageInformation[];
  @Input() set images(value: ImageInformation[])
  {
    this._images = value;
    this.initializeGallery();
  }

  get images(): ImageInformation[] 
  {
    return this._images;
  }

  principalImage: ImageInformation;
  maxCountImage: number = 4;
  numberIterationImage: number = 0;
  imagesShowed: ImageInformation[];

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit() {
    this.initializeGallery();
  }

  changePrincipalImage(image: ImageInformation)
  {
    this.principalImage = image;
  }

  moveCarruselImage(operator:string){
    if (operator == '-' && this.numberIterationImage > 0)
    {
      --this.numberIterationImage;
    }
    else if(operator == '+' && this.numberIterationImage < (this.images.length/this.maxCountImage - 1)) {
      ++this.numberIterationImage;
    }

    this.refreshCarruselImage();
  }

  refreshCarruselImage(){
    this.imagesShowed = [];
    let index = this.numberIterationImage * this.maxCountImage;

    for(let count = 0; count < this.maxCountImage && index < this.images.length; count++)
    {
      this.imagesShowed.push(this.images[index]);
      index++;
    }
  }

  initializeGallery()
  {
    if (this.images && this.images.length > 0)
    {
      this.principalImage = this.images[0];

      this.refreshCarruselImage();
    }
  }

}
