import { Component, OnInit } from '@angular/core';
import { ProductSQL } from 'src/app/models/sqlStruct.model';
import { Tile } from 'src/app/models/tileGrid.model';
import { ProductsService } from 'src/app/services/products.service';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  tiles: Tile[];

  constructor(private productsService: ProductsService) { }

  ngOnInit() {
    this.productsService.GetProducts().subscribe (data => {
      if (data)
      {
        this.tiles = (<Array<ProductSQL>>data).map(u => 
          Object.assign (new Tile,
            {
              title: u.Name,
              cols: 1,
              rows: 1,
              imageUrl: u.ImageUrl
            })
        );
      }
    });
  }

}
