import { Component, Input, OnInit } from '@angular/core';
import { ImageInfo } from 'src/app/models/imageInfo.model';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-imageinfo',
  templateUrl: './imageinfo.component.html',
  styleUrls: ['./imageinfo.component.scss']
})
export class ImageinfoComponent implements OnInit {
  @Input() imageInfos: ImageInfo[];
  imageNotFound: string = "../../../../assets/images/common/imagenotfound.png";
  
  isMobile$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
  }

}
