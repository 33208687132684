import { Component, OnInit } from '@angular/core';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { ScrollerService } from 'src/app/services/scroller.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  isMobile$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(private breakpointObserver: BreakpointObserver, private scroller: ScrollerService, private route: ActivatedRoute) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    let service = (<BehaviorSubject<object>>this.route.params).value['service'];
    if (service)
    {
      this.scroller.scrollToId(service);
    }
    else {
      this.scroller.scrollToTop();
    }
  }

}
