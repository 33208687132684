<div class="container"
    (swipeleft)="changePicture('-')" 
    (swiperight)="changePicture('+')">
    <i *ngIf="!(isMobile$ | async) && !(isTablet$ | async)" [className]="'material-icons rounded md50 left-arrow'" (click)="changePicture('-')">
        arrow_back_ios
    </i>
    <img src="{{showedImage}}" *ngIf="!(isMobile$ | async) && !(isTablet$ | async)">
    <img src="{{showedImage}}" *ngIf="(isMobile$ | async) || (isTablet$ | async)">
    <i *ngIf="!(isMobile$ | async) && !(isTablet$ | async)" [className]="'material-icons rounded md50 right-arrow'" (click)="changePicture('+')">
        arrow_forward_ios
    </i>
    <i *ngIf="!(isMobile$ | async) && !(isTablet$ | async)" [className]="'material-icons rounded md50 close-dialog'" (click)="closeDialog()">
        clear
    </i>
</div>