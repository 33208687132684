import { Component, OnInit } from '@angular/core';
import { PersonalInfo } from 'src/app/models/personalInfo.constants';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ScrollerService } from 'src/app/services/scroller.service';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  personalInfo = PersonalInfo;

  isMobile$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.Tablet])
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(private breakpointObserver: BreakpointObserver, private scroller: ScrollerService) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.scroller.scrollToTop();
  }

}
