import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './components/sections/home/home.component';
import { AboutusComponent } from './components/sections/aboutus/aboutus.component';
import { ProductsComponent } from './components/sections/products/products.component';
import { ServicesComponent } from './components/sections/services/services.component';
import { RentsComponent } from './components/sections/rents/rents.component';
import { ProjectsComponent } from './components/sections/projects/projects.component';
import { DetailsComponent } from './components/sections/details/details.component';
import { ProjectdetailsComponent } from './components/sections/projectdetails/projectdetails.component';


const routes: Routes = [
  {path: '', component:HomeComponent, runGuardsAndResolvers: 'always'},
  {path: 'sobrenosotros', component:AboutusComponent},
  // {path: 'products', component:ProductsComponent},
  {path: 'servicios', redirectTo: 'servicios/', pathMatch: 'full'},
  {path: 'servicios/:service', component:ServicesComponent, runGuardsAndResolvers: 'always'},
  // {path: 'rents', component:RentsComponent},
  {path: 'proyectos', component:ProjectsComponent},
  // {path: 'products/details/:name', component:DetailsComponent, runGuardsAndResolvers: 'always'},
  {path: 'proyectos/detalles/:name', component:ProjectdetailsComponent, runGuardsAndResolvers: 'always'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes , { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy', scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
