<div class="container">
    <h1 class="title-text">Sobre Nosotros</h1>
    <mat-divider class="divider"></mat-divider>
    <mat-grid-list class="grid-container" [cols]="(isMobile$ | async) ? 1 : 2" rowHeight="350px">
        <mat-grid-tile class="info-tile text" [colspan]="1" [rowspan]="1">
            <div>
                <span>
                    Somos una empresa malagueña encargada principalmente en la reforma integral de locales (bares, tiendas
                    de ropa, etc) y hogares.
                    También nos especializamos en la decoración y pintura, donde realizamos todo tipo de técnicas como
                    alisado o microcemento. <br><br>
    
                    Si necesita una reforma, no dude en contactarnos:<br>
                    <span class="bold">Email: </span> <a class="link"
                        href="mailto:{{personalInfo.emailAddress}}">{{personalInfo.emailAddress}}</a><br>
                    <span class="bold">Teléfono: </span> {{personalInfo.phoneNumber}}<br><br>
                    Síganos en nuestras redes sociales para ver nuestros trabajos:<br>
                    <div class="social-media-icon">
                        <a class="link" href="{{personalInfo.facebookUrl}}" target="_blank">
                            <img class="icon facebook-icon" src="../../../../assets/images/common/facebookblackicon.png">
                        </a>
                        <a class="link" href="{{personalInfo.instagramUrl}}" target="_blank">
                            <img class="icon instagram-icon" src="../../../../assets/images/common/instagramblackicon.png">
                        </a>
                    </div>
                    <div class="sentence-container">
                        <span class="bold sentence">"Hacemos posible tu idea"</span>
                    </div>
                </span>
            </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
            <iframe class="youtube" src="https://www.youtube.com/embed/gn-vGkPeZK0" 
                frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen>
            </iframe>
        </mat-grid-tile>
    </mat-grid-list>
</div>
<iframe class="map"
    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3197.5511601023045!2d-4.4534211844284!3d36.733339479159525!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd72f71afa6e87df%3A0xeec70f2423b1b636!2sCam.%20San%20Alberto%2C%2035%2C%2029010%20M%C3%A1laga!5e0!3m2!1ses!2ses!4v1634121721583!5m2!1ses!2ses"
    width="100%" height="400px" style="border:0;" allowfullscreen="" loading="lazy">
</iframe>