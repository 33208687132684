<div class="grid-container">
  <mat-grid-list [cols]="(isMobile$ | async) ? 1 : (isTablet$ | async) ? 2 : 4" rowHeight="400px">
    <mat-grid-tile *ngFor="let card of cards" [colspan]="card.cols" [rowspan]="card.rows">
      <mat-card class="dashboard-card">
        <mat-card-header>
          <mat-card-title>
            {{card.title}}
          </mat-card-title>
        </mat-card-header>
        <ngx-picture mat-card-image alt="{{card.title}}" [src]="card.imageUrl ? card.imageUrl : imageNotFound" [lazyLoad]="true"></ngx-picture>
        <mat-card-content class="dashboard-card-content">
          <button mat-raised-button color="accent" routerLink="{{url}}{{card.title}}">{{buttonText}}</button>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>
</div>
