import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollerService {

  constructor() { }

  scrollToTop() {
    let top = document.getElementById('top');
    if (top !== null) {
      top.scrollIntoView();
      top = null;
    }
  }

  scrollToId (id: string) { 
    let div = document.getElementById(id);
    if (div !== null) {
      div.scrollIntoView({
        behavior: 'auto',
        block: 'center',
        inline: 'center'
      });
      div = null;
    }
  }
}
