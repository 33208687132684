import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { map, shareReplay } from 'rxjs/operators';
import { PersonalInfo } from 'src/app/models/personalInfo.constants';

@Component({
  selector: 'app-footage',
  templateUrl: './footage.component.html',
  styleUrls: ['./footage.component.scss']
})
export class FootageComponent implements OnInit {

  personalInfo = PersonalInfo;

  isMobile$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  isTablet$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Tablet])
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(private breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {
  }

}
