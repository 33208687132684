import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullMaterialModule } from './material-module';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { HomeComponent } from './components/sections/home/home.component';
import { ToolbarComponent } from './components/commons/toolbar/toolbar.component';
import { AboutusComponent } from './components/sections/aboutus/aboutus.component';
import { ProductsComponent } from './components/sections/products/products.component';
import { ServicesComponent } from './components/sections/services/services.component';
import { RentsComponent } from './components/sections/rents/rents.component';
import { ProjectsComponent } from './components/sections/projects/projects.component';
import { ImagegridComponent } from './components/commons/imagegrid/imagegrid.component';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { DetailsComponent } from './components/sections/details/details.component';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ImagegalleryComponent } from './components/commons/imagegallery/imagegallery.component';
import { HeaderComponent } from './components/commons/header/header.component';
import { ImagecarruselComponent } from './components/commons/imagecarrusel/imagecarrusel.component';
import { HomeService } from './services/home.service';
import { HttpClientModule } from '@angular/common/http';
import { ProjectdetailsComponent } from './components/sections/projectdetails/projectdetails.component';
import { ImagecollageComponent } from './components/commons/imagecollage/imagecollage.component';
import { ImagedialogComponent } from './components/commons/imagedialog/imagedialog.component';
import { MyHammerConfig } from './models/hammerConfig.model';
import { CLOUDINARY_CONFIG, NgxPictureModule } from 'ngx-picture';
import { FootageComponent } from './components/commons/footage/footage.component';
import { ImageinfoComponent } from './components/commons/imageinfo/imageinfo.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ToolbarComponent,
    AboutusComponent,
    ProductsComponent,
    ServicesComponent,
    RentsComponent,
    ProjectsComponent,
    ImagegridComponent,
    DetailsComponent,
    ImagegalleryComponent,
    HeaderComponent,
    ImagecarruselComponent,
    ProjectdetailsComponent,
    ImagecollageComponent,
    ImagedialogComponent,
    FootageComponent,
    ImageinfoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FullMaterialModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    HttpClientModule,
    HammerModule,
    NgxPictureModule.forRoot(CLOUDINARY_CONFIG)
  ],
  providers: [
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    {provide: HomeService, useClass: HomeService},
    {provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig}

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
