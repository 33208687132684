import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageDialog } from 'src/app/models/imageDialog.model';
import { Tile } from 'src/app/models/tileGrid.model';
import { Breakpoints, BreakpointObserver } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';


@Component({
  selector: 'app-imagedialog',
  templateUrl: './imagedialog.component.html',
  styleUrls: ['./imagedialog.component.scss']
})
export class ImagedialogComponent implements OnInit {

  showedImage: string;
  imagesCollection: Tile[];

  isMobile$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  isTablet$: Observable<boolean> = this.breakpointObserver.observe([Breakpoints.Tablet, Breakpoints.Medium])
  .pipe(
    map(result => result.matches),
    shareReplay()
  );

  constructor(@Inject(MAT_DIALOG_DATA) public data: ImageDialog, private breakpointObserver: BreakpointObserver, private dialogRef: MatDialogRef<ImagedialogComponent>) { }

  ngOnInit(): void {
    this.showedImage = this.data.imageUrl;
    this.imagesCollection = this.data.images;
  }

  changePicture(operator: string)
  {
    var tileShowed = this.imagesCollection.filter(i => i.imageUrl == this.showedImage);
    var indexImage = this.imagesCollection.indexOf(tileShowed.pop());

    if (operator === '+')
    {
      if (indexImage == this.imagesCollection.length - 1)
      {
        this.showedImage = this.imagesCollection[0].imageUrl;
      }
      else
      {
        this.showedImage = this.imagesCollection[indexImage + 1].imageUrl;
      }
    }

    if (operator === '-')
    {
      if (indexImage == 0)
      {
        this.showedImage = this.imagesCollection[this.imagesCollection.length - 1].imageUrl;
      }
      else
      {
        this.showedImage = this.imagesCollection[indexImage - 1].imageUrl;
      }
    }
  }

  closeDialog()
  {
    this.dialogRef.close();
  }
}
